import { ResponsibilityContainer } from "./styles";

export const Responsibility = () => {
  return (
    <ResponsibilityContainer>
      <img
        src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/b41cbbcd-4b24-4f14-9c3a-36771c5cab00/public"
        alt="Jogue com Responsabilidade"
      />
    </ResponsibilityContainer>
  );
};
