import styled from "styled-components";

// Create a styled img component
const HeadImg = styled.img`
  width: 180px;
  display: block;
  margin: auto;
  padding: 10px 0px;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    width: 250px;
  }
`;

export default function Headline() {
  return (
    <>
      <HeadImg src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/850a0a5a-b9e5-453a-5652-bf14b4bfac00/public" />
    </>
  );
}
